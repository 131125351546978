const initialValues={
    show:true,
    breadcrumb:[],
    speciality:[]
}
export const Reducer=(state=initialValues,action)=>{
switch(action?.type){
    case "SHOW":return {...state,show:action?.data}
    case "BREAD_CRUMB":return {...state,breadcrumb:action?.data}
    case "SPECIALITY":return {speciality:action?.data}
    default :return state
}
}