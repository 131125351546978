export const Base_Url = "https://credapi.hitechhealth.us/api/";
export const Image_Base_Url = "https://credapi.hitechhealth.us/assets";

export const urls = {
  logins: {
    signup: "SignIn/ProviderSignUp",
    login: "SignIn/SignIn",
    forgotPassword: "Forgotpassword/ForgotPassword",
    changePassword: "Forgotpassword/ResetPassword",
    getCreateUserInfo:"Settings/GetUserInfo"
  },
  masters:{
    save:"Settings/SaveUpdateGlobalMaster",
    getList:"Settings/GetAllGlobalMaster",
    status:"Settings/UpdateGlobalMasterStatus",

  },
  dashboard: {},
  facility: {},
  doctor: {},
  alliedHealth: {},
  applicationBuilder: {
    saveFrom: "Forms/SaveUpdateForms",
    getallForm: "Forms/GetAllForms",
    saveTemplate:"Forms/SaveUpdateFormPackage",
    getAllTemplate:"Forms/GetAllFormPackage",
    sortingForms:"Forms/FormMapping",
    credentialingSave:"Forms/SaveCredentialPreferences",
    getAllCredTemplates:"Forms/GetAllCredentialPreferences",
    applicationTemplateStatus:"Forms/TemplateStatus",
    applicationTemplateFormsStatus:"Forms/UpdateFormStatus",
    duplicateApplicationTemplate:"/Forms/DuplicateTemplate",
    templateFormDuplicates:"Forms/DuplicateForms"
  },
  settings: {
    getStatesdd: "Home/BindDropdown",
    createUser: "Settings/CreateUser",
    getAllUsers: "Settings/GetAllUsers",
    sendPassword: "Settings/SendEmail",
    deleteUser: "Settings/DeleteUsers",
    createFacilityLocation:"Facility/SaveUpdateFacilities",
    getAllFacilityLocation:"Facility/GetAllFacilities",
    configaration:"/Settings/Configuration",
    getAllconfiguration:"/Settings/GetConfiguration",
    facilityManagementSave:"Organization/SaveUpdateOrganization",
    facilityManagementAll:"Organization/GetAllOrganization",
    statusFacilitManagement:"Organization/UpdateOrganizationStatus"
  },
  activityLogs: {},
  account: {
    changePassword:"Forgotpassword/UpdateAccount"
  },
  Appointments: {
    initialcreateAppointment:"",
    specialityByTemplate:"Appointment/GetTemplateFormsBySpeciality",
    createProvider:"SignIn/SaveProvider",
    createTags:"Settings/SaveUpdateTags",
    getUserTemplates:"Appointment/GetApplicationPacket",
    sendmailtoprovider:"Appointment/SendEmailToProvider",
    getUsersBySearch:"Appointment/GetUsersBySearch"
  },
  applicationInprogress: {
    createDeligate:"/Settings/AssignDelegate"
  },
  needAttentions: {},
};
